<template>
	<div>
		<carousel v-if="$route.params.widget == 'carousel'"></carousel>
		<banner v-if="$route.params.type == 'rectangle' || $route.params.type == 'leaderboard'"></banner>
		<traderInfo v-if="$route.name == 'traderInfo'" />
	</div>
</template>
<script>
	import carousel from './carousel.vue'
	import banner from './banner.vue'
	import traderInfo from './trader-info.vue'
	export default{
		data(){
			return{}
		},
		components:{ carousel,banner,traderInfo },
		// created(){
		// 	console.log(this.$route.params)
		// }
	}
</script>